.pageTitleSection {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 30px;
}
.titleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
}

.pageTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: var(--theme-primary);
  letter-spacing: 3px !important;
  text-transform: uppercase;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

/* .button {
  outline: none;
  background-color: #56ccf2;
  border-radius: 4px;
  border: 1px solid #56ccf2;
  font-weight: 550;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  padding: 5px 20px;
}

.button:hover {
  cursor: pointer;
  background-color: #00bbcc;
} */

.contentDivider {
  background: var(--theme-primary);
  border-radius: 2px;
  width: 100%;
  height: 2px;
  border: none;
  margin: 0;
}
