.card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin: 16px;
    max-width: 100%;
}

.col-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
}

.card-icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    background: #333;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    aspect-ratio: 1/1;
}

.card-icon svg {
    fill: white;
}

.card-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}

.card-title {
    font-size: 16px;
    font-weight: bold;
    color: #555;
}

.card-value {
    font-size: 24px;
    font-weight: bold;
    color: #222;
}

.card-percentage {
    font-size: 14px;
    color: green;
}

.chart-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin: 16px;
    max-width: 400px;
    text-align: center;
}

.chart-title {
    font-size: 14px;
    color: #888;
}

.chart-subtitle {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 16px;
}

.chart-wrapper {
    position: relative;
    height: 200px;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding-left: 30px;
    padding-bottom: 10px;
}

.chart {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
        align-items: flex-end;
    -ms-flex-pack: justify;
        justify-content: space-between;
    height: 100%;
    position: relative;
}

.bar-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex: 1;
        flex: 1;
}

.bar {
    width: 30px;
    background-color: orange;
    border-radius: 4px 4px 0 0;
}

.label {
    margin-top: 5px;
    font-size: 12px;
    color: #666;
}

.y-label {
    position: absolute;
    left: -30px;
    font-size: 12px;
    color: #666;
    -webkit-transform: translateY(50%);
        -ms-transform: translateY(50%);
            transform: translateY(50%);
}
