:root {
  --theme-primary: #2b404e;
  --theme-primary-light: #4b748f;
  --theme-primary-dark: #29333a;
  --theme-primary-label: var(--theme-primary);
  /* --theme-primary-label: rgb(71 120 124); */

  --theme-link-selected: #87a6bb;

  --theme-secondary: rgba(224, 156, 53, 1);
  --theme-secondary-light: rgba(238, 158, 37, 1);
  --theme-secondary-dark: rgba(182, 117, 19, 1);

  --theme-common-black: rgba(50, 50, 50, 1);
  --theme-border-color-grey: #dfdfdf;
  --theme-border-color-grey-light: #2b404e26;

  --theme-light-grey: #f3f3f3;
}

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

img {
  display: inline-block;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ContentHeader_pageTitleSection {
  margin-bottom: 30px;
}
html,
body,
#root {
  height: 100%;
}
body {
  background-color: #f9f9f9;
}
body *:not(.ContentHeader_pageTitleSection, .ck-content > *) {
  font-family: Poppins !important;
  font-size: 15px;
  /* letter-spacing: 1px; */
}

.MuiTableContainer-root {
  border-radius: 4px !important;
  box-shadow: 1px 2px 1px rgb(167 167 167 / 14%) !important;
  border: 1px solid var(--theme-border-color-grey-light);
}
.MuiTableContainer-root thead th {
  font-size: 13px !important;
}
.MuiTableContainer-root tbody th,
.MuiTableContainer-root tbody td {
  font-size: 13px !important;
  color: #5c5c5c;
  border: 0 !important;
  padding: 0 20px;
  height: 44px;
}
.MuiTableContainer-root tbody .MuiTableRow-root {
  background-color: rgb(157 157 157 / 4%);
  border-bottom: 1px solid #efefef !important;
}
.MuiTableContainer-root tbody tr td:last-child img {
  padding: 10px;
  border: 1px solid #e9e9e9;
}

.MuiPagination-root {
  justify-content: flex-end !important;
}

.MuiPagination-root button {
  /* border: 1px solid black !important; */
  font-weight: normal !important;
  font-size: 13px !important;
  background: #f1f1f1;
}

.ContentHeader_pageTitle__3-KD_ {
  letter-spacing: 2px;
}
.ContentHeader_pageTitle__3-KD_ * {
  font-size: 18px !important;
}

.content {
  background-color: red !important;
  padding: 100px;
}

input,
textarea,
select {
  padding: 10px 20px !important;
  border-radius: 4px !important;
  border: 1px solid #dfdfdf !important;
}

input#react-select-3-input,
input#react-select-5-input,
input#react-select-4-input,
input#react-select-2-input,
input#react-select-1-input {
  border: 1px solid #fff !important;
  padding: 0px 0px !important;
}
.css-qbdosj-Input input {
  border: 1px solid #fff !important;
  padding: 0px 0px !important;
}

.eeIYIc .react-datepicker-wrapper,
.eeIYIc .react-datepicker__input-container,
.eeIYIc .react-datepicker__input-container input {
  height: initial !important;
}

form span {
  /* padding: 10px */
}

.MuiAccordion-root {
  box-shadow: 1px 1px 4px rgb(0 0 0 / 10%) !important;
  border-radius: 4px !important;
  margin: 20px 0 !important;
  border: 1px solid var(--theme-border-color-grey-light);
}
.MuiAccordion-root:before {
  display: none !important;
}
.MuiAccordionSummary-root {
  border-bottom: 1px solid #e9e9e9 !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.MuiAccordion-root .MuiTypography-root {
  font-weight: bold !important;
  letter-spacing: 1px !important;
  text-transform: uppercase;
  font-size: 13px !important;
}
.MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 10px !important;
  background-color: red !important;
}

.MuiAccordionDetails-root {
  padding: 20px 50px !important;
}

.MuiButton-sizeSmall {
  padding: 1px 9px !important;
  font-size: 14px !important;
}

.ck.ck-editor__editable_inline {
  height: 200px;
}
/* 
.css-1lcvr6a-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: #f5f5f5 !important;
  color: #2B404E !important;
}

.Orgaos_treeRoot__Hu7W6 ul li div:nth-child(2) {
  padding-left: 24px ;
}

.Orgaos_treeRoot__Hu7W6 ul li ul li div:nth-child(2) {
  padding-left: 24px ;
} */

.css-1pqm26d-MuiAvatar-img {
  width: 100%;
  height: auto !important;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
}

nav.MuiTypography-body1 {
  margin-bottom: 12px !important;
}
.MuiTypography-root li a {
  font-size: 12px !important;
}

.MuiTypography-root li {
  font-size: 15px !important;
}
.MuiTypography-root li p {
  font-size: 12px !important;
}
.MuiPaginationItem-page {
  margin: 3px 0px !important;
}
.MuiBreadcrumbs-ol {
  align-items: baseline !important;
}

.MuiTypography-root {
  white-space: pre-wrap !important;
  color: #2b404e;
  line-height: 17px !important;
}

.menumob {
  display: none;
}

@media (max-width: 1024px) {
  .sideMenu {
    display: none;
  }

  .sideMenuSectionTitle {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 24px;
    display: flex;
    align-items: center;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .menuSection {
    margin-bottom: 10px;
  }

  .sideMenuSectionTitleContainer {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    /* background: #262626; */
    padding: 10px 30px;
  }

  .sideMenuSectionItemContainer {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 0.5rem 30px;
    gap: 0.8rem;
  }

  .sideMenuSectionItemContainer:hover {
    cursor: pointer;
    background-color: rgb(255 255 255 / 22%);
  }

  .sideMenuSectionItemContainerSelected {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255 255 255 / 22%);
    border-radius: 4px;
  }

  .sideMenuSectionItemName {
    /* font-family: Roboto; */
    font-size: 15px;
    line-height: 18px;
    color: #838383;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .menumob {
    display: block;
  }
}

/* Multi-Part CSS */
.checkinput {
  position: absolute;
  /* Position the checkbox in upper left corner */
  opacity: 0;
}

/* Styling Parts */
#part1,
#part2,
#part3,
#part4 {
  z-index: 200;
  /* display: block; */
  height: auto;
  opacity: 1;
}

/* Hidden Parts */
#part2,
#part3,
#part4 {
  opacity: 0;
  height: 0;
  /* overflow: hidden; */
}

/* Show Step 2 & Hide Step 1 */
#step2:checked ~ #part2 {
  opacity: 1;
  height: auto;
}

#step2:checked ~ #part1 {
  opacity: 0;
  height: 0;
  display: none;
}

/* Show Step 3  & Hide Step 2 */
#step3:checked ~ #part3 {
  opacity: 1;
  height: auto;
}

#step3:checked ~ #part2 {
  opacity: 0;
  width: 0;
  height: 0;
}

#step4:checked ~ #part4 {
  opacity: 1;
  height: auto;
}

#step4:checked ~ #part3 {
  opacity: 0;
  width: 0;
  height: 0;
}

@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1280px) {
}
@media (min-width: 1536px) {
}
