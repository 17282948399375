.container {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.content {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 1rem;
}

.sessarContainer {
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
}
.alocationcontainer {
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
}

.userdata {
  flex-basis: 38%;
}

.information {
  flex-basis: 60%;
}

.bodyContainer {
  display: flex;
}

.curriculum {
  height: 28px;
  background: #2b404e !important;
  border-radius: 2px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  float: right !important;
  margin-bottom: 10px !important;
}

.quadroCVBackButton {
  font-size: 2.2rem !important;
  color: white;
  cursor: pointer;
}

.quadroCVBackButton:hover {
  color: #dfdddd;
}
