.headerContainer {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  height: 80vh;
}

.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}

.text-center {
  font-weight: 400;
  text-align: center;
}
.gap-2 {
  gap: 2rem;
}
.border-r-2 {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.border-none {
  border: none;
}
.text-lg {
  font-size: 22px;
}
.imgcenter {
  text-align: center;
  margin-bottom: 40px;
}
.mt10 {
  margin-top: 20px;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}
.title {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
}
.myform {
  margin-top: 20px;
  padding: 40px;
  background-color: #f9f9f9 !important;
  box-shadow: none !important;
  border-radius: 4px;
}

.p-4 {
  padding: 4px;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

.w-11\/12 {
  width: 91.666667%;
}
.w-1\/12 {
  width: 8.333333%;
}

.w-8\/12 {
  width: 66.666667%;
}

.w-96 {
  width: 24rem;
}

.px-8 {
  padding-left: 2.6rem;
  padding-right: 2.6rem;
}
.py-5 {
  padding-top: 2.6rem;
  padding-bottom: 2.6rem;
}
.py-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
.bbred {
  border-top: 9px solid #81d8f8;
}

.bg-white {
  background-color: #fff;
}

.shadow-store {
  box-shadow: 0 4px 60px rgb(0 0 0 / 11%);
}
.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.uppercase {
  text-transform: uppercase;
}
.font-bold {
  font-weight: 700;
}
.font-medium {
  font-size: 26px;
  font-weight: bold;
}
.py-4 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.px-16 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.bg-myred {
  background-color: #81d8f8;
}
.rounded-lg {
  border-radius: 2px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.gap-10 {
  gap: 2.5rem !important;
}
.gap-6 {
  gap: 1.5rem !important;
}
.justify-end {
  justify-content: flex-end !important;
}
.mb-4 {
  margin-bottom: 1rem !important;
}
.mt-10 {
  margin-top: 2.5rem !important;
}

.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.mt-12 {
  margin-top: 3rem;
}
.z-20 {
  z-index: 20;
}
.absolute {
  position: absolute;
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
}

.shadow-md,
.shadow-sm {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.font-bold {
  font-weight: 700;
}
.bg-myred {
  background-color: #81d8f8;
}
.border-mygreen {
  border: 2px solid #ccc;
}
.bg-myredr {
  border: 1px solid #81d8f8;
  border-radius: 100%;
  background-color: #fff;
}

.rounded-full {
  border-radius: 9999px;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.w-10 {
  width: 2.5rem;
}
.h-10 {
  height: 2.5rem;
}
.flex {
  display: flex;
}
.border-myred {
  border: 2px solid #81d8f8;
}
.text-green {
  color: #81d8f8;
}
.border-2 {
  border-width: 2px;
}

.onebefore:before {
  top: 13px !important;
}
.twobefore:before {
  top: 100px !important;
}
.threebefore:before {
  top: 188px !important;
}
.allbefore:before {
  content: '';
  position: absolute;
  width: 3px;
  margin-right: -126px;
  top: 10px;
  transform: rotate(271deg);
  rotate: revert(360);
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 14px 14px;
}
.h-12 {
  height: 3rem;
}
.bstepper,
.bstepper2 {
  position: absolute;
  width: 30px;
  height: 0;
  right: 5px;
  transform: rotate(90deg);
}
.bstepper2 {
  top: 150px;
}
.bstepper3 {
  position: absolute;
  width: 30px;
  height: 0;
  right: 5px;
  top: 240px;
  transform: rotate(90deg);
}

.bstepper {
  top: 60px;
}

.border {
  border-width: 1px !important;
}
span {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
}

