.container {
  /* display: flex;
    flex-direction: column; */
  padding: 0;
  margin: 0;
  gap: 1rem;
  height: 100%;
}

.cardContainer {
  background-color: #ffffff;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 10%);
  border-radius: 4px;
  /* display: flex;
    flex-direction: column; */
  margin-bottom: 1.5rem;
  /* height: 100%; */
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
  margin-bottom: 1rem;
}

/* .columnsContainer {
    display: flex;
    justify-content: space-between;
  } */

.column {
  /* margin-bottom: 20px; */
}

.valueContainer {
  /* display: flex;
    flex-direction: column; */
  margin-bottom: 1px;
}

.value {
  color: #333333;
  font-style: normal;
  font-weight: normal;
  /* font-size: 16px;
    line-height: 24px; */

  text-overflow: ellipsis;
  white-space: nowrap;
  /* overflow: hidden; */
  /* max-width: 12rem; */
}

form {
  background-color: white;
  /* padding: 50px 50px 0 50px; */
  background-color: #ffffff;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 10%);
  border-radius: 4px;
}

.gridContainer {
  height: 600px;
  overflow: auto;
  width: 90%;
  margin: auto;
}

.fieldGroup {
  margin-bottom: 10px !important;
}

input,
textarea,
select {
  /* width: 90%; */
}

label {
  font-size: 13px !important;
  padding: 0 !important;
}

.leftWrapper {
  /* background-color: red; */
  padding-right: 50px;
}

.rightWrapper {
  border-left: 1px solid #f3f3f3;
  /* background-color: blue; */
  padding-left: 50px;
}

.thumbnail {
  width: 200px;
  height: 200px;
  border: 1px solid #f3f3f3;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.thumbnail:hover .darkOverlay {
  display: block;
}

.darkOverlay {
  cursor: pointer;
  display: none;
  background-color: #0000007d;
  width: 100%;
  height: 100%;
  position: absolute;
}

img {
  /* margin: 20px 0; */
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
}

.buttonContainer button {
  margin: 0 30px !important;
  width: 200px;
}

.maskCircle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border-radius: 50%;
  overflow: hidden;
  width: 300px;
  height: 300px */
}
.UpLoadButton {
  margin-left: auto;
  height: 25px;
  /* background: #2B404E; */
  border-radius: 4px;
  min-width: 1px !important;
}