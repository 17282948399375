.sideMenu {
  width: 310px;
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #f1f1f1;
  padding-left: 1rem;
  padding-top: 1.5rem;
  padding: 30px 0;
  border-right: 1px solid var(--theme-border-color-grey);
  box-shadow: 1px 0 0 white;
}


.sideMenu i {
  float: right
}

.sideMenuSectionTitle {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.menuSection {
  margin-bottom: 10px;
}

.sideMenuSectionTitleContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  /* background: #262626; */
  padding: 10px 30px
}

.sideMenuSectionItemContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0.5rem 30px;
  gap: 0.8rem;
}

.sideMenuSectionItemContainer:hover {
  cursor: pointer;
  background-color: rgb(255 255 255 / 22%);
}

.sideMenuSectionItemContainerSelected {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255 255 255 / 22%);
  border-radius: 4px;
}

.sideMenuSectionItemName {
  /* font-family: Roboto; */
  font-size: 15px;
  line-height: 18px;
  color: #838383;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menumob {
  display: none;
}

@media (max-width: 1024px) {
  .sideMenu {
    display: none;
  }
  .menumob {
    display: block;
  }
}
