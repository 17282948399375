.headerContainer {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  height: 4.8125rem;
  background-color: var(--theme-primary);
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 99;
  box-shadow: 0 0 5px #0000006b;
}

.headerContent {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  height: 100%;
}

.logo {
  width: 59px;
  height: 70px;
  margin-right: auto;
}

.entitySwitcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.entitySwitcherText {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: #e7e7e7;
}

.userInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userInfoList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  /* position: absolute; */
  background-color: #fff;
  /* top: 6rem; */
  width: 9rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.userInfoListItem {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0.5rem 1rem;
}

.userInfoListItem:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.MuiSvgIcon-root,
.MuiSelect-icon {
  color: #56ccf2 !important;
}
