.flex {
    width: 100%;
    display: -ms-flexbox !important;
    display: flex !important;
}

.flex-col {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
}

.contain-dash {
    padding: 25px 50px;
    width: 100%;
}

.card-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 16px;
    margin-top: 16px;
}
