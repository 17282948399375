.searchInput {
  border: none !important;
  background-color: #ffffff;
  box-sizing: border-box;
  outline: none;
}

.uploads {
  /* border: 1px solid #2B404E; */
  padding: 4px 8px !important;
  border-radius: 2px;
  display: grid;
  /* grid-template-columns: 50% 25% 25%; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.uploads span {
  font-size: 0.75rem;
  padding: 2px;
}

.textEllips {
  border-radius: 4px;
  padding: 3px 8px !important;
  border: 1px solid #999;
  overflow: hidden;
    text-overflow: ellipsis;
white-space: nowrap;
}
.labels {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.labels label{
  font-size: 12px !important;
}
.labels input, .labels select{
  font-size: 12px !important;
}

.w20 {
  width: 30%;
}
.w80 {
  width: 70%;
}
