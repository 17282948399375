
.mycontainer {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  margin-left: 8rem;
  margin-right: 8rem;
}

.myflex {
  display: flex;
}

.boxInput {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  gap: 1rem;
}
.boxContainer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;
}

@media (max-width: 768px) {
  .mycontainer {
    padding: 1rem 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .myflex {
    display: flex;
    flex-direction: column !important;
  }
  .boxContainer {
    flex-direction: column !important;
  }
  .boxContainer div {
    width: 100%;
    text-align: left;
  }
}
